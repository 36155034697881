.servicos-home {
  padding-top: 0px;
}

// Bloco serviço
.servico {
  position: relative;
  overflow: hidden;
  max-width: 500px;
  //box-shadow: 9px -4px 0px 0px rgba(255, 0, 0, 0.8);
  margin-bottom: 20px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    //border-left: 1px solid rgba(#fff, 0.3);
    //border-bottom: 1px solid rgba(#fff, 0.3);
    transition: 0.5s ease-out;

    // MOBILE
    @include mq(s) {
    }
  }

  &.pag-servicos {
    figure {
      transform: scale(1.2);

      // MOBILE
      @include mq(s) {
        transform: scale(1.5);
      }
    }
  }

  // Foto
  figure img {
    transform: scale(1.1);
    transition: 0.5s ease-out;
  }
  &:hover figure img {
    transform: scale(1.2);
  }

  // Máscara
  span {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(to top, rgba(#000, 0.95), transparent);
    transition: 0.3s ease-out;
    text-align: center;
    padding: 20px 20px;
    padding-top: 150px;

    /* > div {
      position: absolute;
      bottom: 30px;
      left: 30px;
    } */
  }
  &:hover span {
    //background: rgba(#000, 0.6);
  }

  // Título
  h2 {
    font-size: 16px;
    line-height: 1.2;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(#000, 0.3);
    margin-top: 40px;
    margin-bottom: 15px;

    // MOBILE
    @include mq(s) {
      margin-top: 0px;
      font-size: 18px;
    }
  }

  // Botão
  .btn {
    //transform: scale(0.5);

    // MOBILE
    @include mq(s) {
      opacity: 1;
      transform: scale(1);
    }
  }
  &:hover .btn {
    &.btn-primario {
      background: darken($cor_primaria, 10%);
    }
  }
}

// Carrosel
.carrosel-servicos {
  //margin-top: px;

  // Bloco serviço
  .servico {
    display: block;
    width: 100%;
    margin: 5px 10px;
  }
}
