// Botão padrão
.btn {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  background: rgba(#000, 0.1);
  border: none;
  font-family: $fonte_titulo;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  color: $cor_fonte_escura;
  text-align: center;
  padding: 15px 30px;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background: rgba(#000, 0.15);
  }

  // Inativo
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  i {
    margin-right: 10px;
  }

  // Outline
  &.outline {
    background: none;
    border: 1px solid #ddd;
    color: $cor_fonte_clara;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: #ddd;
      color: $cor_fonte_escura;
    }
  }

  // Botão primário
  &.btn-primario {
    background: $cor_primaria;
    color: #fff;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: darken($cor_primaria, 10%);
    }

    &.active:hover {
      background: $cor_primaria;
    }

    // Outline
    &.outline {
      background: none;
      border: 1px solid $cor_primaria;
      color: $cor_primaria;

      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $cor_primaria;
        color: #fff;
      }
    }
  }

  // Botão secundário
  &.btn-secundario {
    background: $cor_secundaria;
    color: #fff;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: darken($cor_secundaria, 10%);
    }

    &.active:hover {
      background: $cor_secundaria;
    }

    // Outline
    &.outline {
      background: none;
      border: 1px solid $cor_secundaria;
      color: $cor_secundaria;

      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $cor_secundaria;
        color: #fff;
      }
    }
  }

  // Botão terciario
  &.btn-terciario {
    background: #fff;
    color: $cor_fonte_escura;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: darken(#fff, 10%);
    }

    &.active:hover {
      background: #fff;
    }

    // Outline
    &.outline {
      background: none;
      border: 1px solid #fff;
      color: #fff;

      &:hover,
      &:focus,
      &:active,
      &.active {
        background: #fff;
        color: #333;
      }
    }
  }

  // Botão pequeno
  &.btn-sm {
    padding: 10px 20px;
    font-size: 14px;
  }

  // Botão grande
  &.btn-lg {
    padding: 20px 40px;
    font-size: 20px;
  }
}

// Botão container
.btn-container {
  float: left;
  width: 100%;
  margin-top: 20px;
  text-align: center;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}
