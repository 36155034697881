// Banner do topo
.institucional-banner {
  position: relative;
  float: left;
  width: 100%;
  height: 200px;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.banner-titulo {
    height: auto;
    padding: 55px 0;
    text-shadow: 2px 2px 2px rgba(#000, 0.3);
    padding-bottom: 70px;

    // MOBILE
    @include mq(s) {
      padding-top: 30px;
      padding-bottom: 40px;
    }
  }

  .pag-contato {
    margin-bottom: 75px;

    // MOBILE
    @include mq(s) {
      margin-bottom: 30px;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    position: relative;
    font-family: $fonte_titulo;
    font-weight: bold;
    text-transform: uppercase;

    // Linha inferior
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;
      margin: 0 auto;
      width: 100px;
      height: 3px;
      background: #fff;

      // MOBILE
      @include mq(s) {
        bottom: -10px;
      }
    }

    // MOBILE
    @include mq(s) {
      font-size: 20px;
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($cor_primaria, 0.9);
  }

  h2 {
    color: #fff;
    margin-top: 65px;

    // MOBILE
    @include mq(s) {
      margin-top: 34px;
      font-size: 13px;
    }

    strong {
      color: #fff;
      font-weight: bold;
    }
  }

  // TABLET
  @include mq(m) {
    height: 150px;
  }

  // MOBILE
  @include mq(s) {
    height: 100px;
  }
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {
  // DESKTOP
  @include mq(l) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.segura-sobre {
  margin-top: 30px;
}

.empresa-sobre {
  background: $cor_fundo2;
  height: 300px;
  padding: 20px;
  transition: 0.3s ease-out;
  margin-bottom: 20px;

  // TABLET
  @include mq(m) {
    height: 360px;
  }

  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
    height: auto;
  }

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .titulo {
    //color: #fff;
    font-size: 20px;
    margin-bottom: 15px;

    &::after {
      display: none;
    }

    // MOBILE
    @include mq(s) {
      margin-bottom: 30px;
    }
  }
  .texto {
    //color: #fff;
    font-weight: 400;

    ul {
      padding-left: 0px;

      li {
        margin-bottom: 0px;
        list-style: none;
      }
    }
  }
  .segura-icon {
    text-align: center;
    color: #b21d23;
    font-size: 64px;
  }
}
