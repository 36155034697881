// Seção
.home-sobre {
  //background: $cor_fundo2;
}

// Texto
.home-sobre-texto {
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 2;
  background: $cor_primaria;
  padding: 40px;
  height: 330px;
  //border-radius: 5px;

  // TABLET
  @include mq(m) {
    position: static;
    padding: 20px;
    height: auto;
  }

  // MOBILE
  @include mq(s) {
    position: static;
    padding: 20px;
    height: auto;
    margin-top: 20px;
  }

  .titulo {
    color: #fff;
    margin-bottom: 40px;

    // Linha inferior
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;
      margin: 0 auto;
      width: 100px;
      height: 3px;
      background: #fff;

      // MOBILE
      @include mq(s) {
        bottom: -10px;
      }
    }
  }

  // Texto
  .texto {
    text-align: left;
    font-size: 17px;
    line-height: 24px;
    font-weight: 300;
    color: #fff;

    strong {
      color: #fff;
      font-weight: bold;
    }
  }

  .segura-btn {
    text-align: center;
  }

  // Botão
  .btn-container {
    margin-top: 40px;

    // MOBILE
    @include mq(s) {
      text-align: center !important;
    }
  }
}

// Foto
.home-sobre-foto {
  float: right;
  overflow: hidden;
  //height: 400px;
  //border-radius: 5px;
  border-right: 3px solid $cor_primaria;

  // TABLET
  @include mq(m) {
    height: auto;
  }

  // MOBILE
  @include mq(s) {
    height: auto;
  }

  img {
    transition: 0.3s ease-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
}
