.home-clientes {
  background: $cor_fundo2;
}

// Cliente bloco item
.cliente-item {
  display: block;
  margin: 5px 10px;
  border: 1px solid rgba(#000, 0.1);
  //border-radius: 8px;
  overflow: hidden;
  background: $cor_fundo2;

  // Imagem
  img {
    //filter: grayscale(100%);
    transition: 0.3s ease-out;
  }
  &:hover img {
    position: relative;
    z-index: 2;
    transform: scale(1.1);
  }
}
