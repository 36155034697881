// Galeria caixa
.caixa-galeria {
  margin-bottom: 20px;

  // Foto
  figure {
    float: left;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    //margin-bottom: 5px;
    overflow: hidden;

    img {
      transition: 0.3s ease-out;
    }
  }
  &:hover {
    figure img {
      transform: scale(1.1);
    }
  }

  // Título
  h2 {
    float: left;
    height: 46px;
    width: 100%;
    color: #333;
    font-size: 15px;
    font-weight: bold;
    /* text-align: center; */
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    //background: #f1f3f4;
    padding: 8px 16px;

    // MOBILE
    @include mq(s) {
      font-size: 13px;
      height: auto;
    }

    // TABLET
    @include mq(m) {
      padding: 8px 13px;
      margin-bottom: 0px;
      font-size: 13px;
      height: 63px;
    }

    i {
      color: $cor_primaria;
      margin-right: 10px;
    }
  }
  &:hover {
    h2 {
      color: $cor_primaria;
    }
  }
}

// Lista de fotos detalhe
.galeria-lista {
  float: left;
  width: 100%;
  margin-top: 20px;

  // MOBILE
  @include mq(s) {
    margin-top: 10px;
  }
}

// Galeria caixa detalhe
.galeria-thumb {
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;

  img {
    transition: 0.3s ease-out;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
